.side-menu-component-body {
	display: flex;
	flex-direction: column;
	width: 15%;
	height: 90%;
	background-color: #213a44;
	position: relative;
}

.version-txt {
	position: absolute;
	bottom: 10px;
	left: 10px;
	font-family: "Mollen";
	font-size: medium;
	color: white;
}
