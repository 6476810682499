.camera-card-txt {
	font-family: "Mollen";
	font-size: x-large;
	color: var(--localGrey);
}

.camera-card-content-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}
