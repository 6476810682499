.camera-preview-body {
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 33%;
	margin-right: 10px;
	margin-bottom: 10px;
	border: 2px solid #213a44;
	box-sizing: border-box;
	padding: 10px;
	flex-basis: 30%;
	flex-grow: 1;
	cursor: pointer;
}

.camera-preview-body-2-cameras {
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 49%;
	margin-right: 10px;
	margin-bottom: 10px;
	border: 2px solid #213a44;
	box-sizing: border-box;
	padding: 10px;
	flex-basis: 30%;
	flex-grow: 1;
	cursor: pointer;
}

.camera-preview-body-1-camera {
	display: flex;
	flex-direction: column;
	align-items: center;
	/* max-width: 49%; */
	margin-right: 10px;
	margin-bottom: 10px;
	border: 2px solid #213a44;
	box-sizing: border-box;
	padding: 10px;
	flex-basis: 30%;
	flex-grow: 1;
	cursor: pointer;
}

.camera-preview-title-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 100%;
}

.camera-preview-title-weather-txt {
	font-family: "MollenBold";
	font-size: x-large;
	color: #213a44;
}

.camera-preview-weather-icon {
	padding-left: 4px;
	padding-right: 4px;
	padding-top: 2px;
	padding-bottom: 2px;
	border-radius: 100px;
}

.camera-preview-weather-icon:hover {
	background-color: rgb(214, 211, 211);
	cursor: pointer;
}

@media (max-width: 1300px) {
	.camera-preview-body {
		display: flex;
		flex-direction: column;
		align-items: center;
		max-width: 48%;
		margin-right: 10px;
		margin-bottom: 10px;
		border: 2px solid #213a44;
		box-sizing: border-box;
		padding: 10px;
		flex-basis: 48%;
		flex-grow: 1;
	}
}

@media (max-width: 1150px) {
	.camera-preview-body-2-cameras {
		max-width: 98%;
		flex-basis: 98%;
	}
	.camera-preview-body {
		max-width: 98%;
		flex-basis: 98%;
	}
}

.camera-preview-title-txt {
	font-family: "MollenBold";
	font-size: xx-large;
	color: #213a44;
}

.camera-preview-image-box {
	width: 100%;
	height: 100%;
}

.camera-preview-image {
	width: 100%;
	height: 100%;
	object-fit: contain;
	overflow: hidden;
}

.camera-preview-actions-container {
	display: flex;
	flex-direction: row;
	gap: 10px;
}
