.notifications-container {
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;
}

.notifications-container-component-wrapper {
	z-index: 12;
	position: absolute;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: fit-content;
	height: 50px;
	bottom: 25px;
	left: 40%;
	border-radius: 10px;
	padding-left: 60px;
	padding-right: 60px;
	padding-top: 40px;
	padding-bottom: 40px;
	background-color: #36474f;
	box-shadow: 1px 1px 5px black;
	animation: fadeInAnimation 0.2s linear;
}

.notifications-container-component-wrapper-success {
	z-index: 12;
	position: absolute;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: fit-content;
	height: 50px;
	bottom: 25px;
	min-width: 10%;
	border-radius: 10px;
	padding-left: 60px;
	padding-right: 60px;
	padding-top: 40px;
	padding-bottom: 40px;
	background-color: #50c878;
	box-shadow: 1px 1px 5px black;
	animation: fadeInAnimation 0.2s linear;
}

.notifications-container-component-wrapper-failure {
	z-index: 12;
	position: absolute;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: fit-content;
	height: 50px;
	bottom: 25px;
	min-width: 10%;
	border-radius: 10px;
	padding-left: 60px;
	padding-right: 60px;
	padding-top: 40px;
	padding-bottom: 40px;
	background-color: red;
	box-shadow: 1px 1px 5px black;
	animation: fadeInAnimation 0.2s linear;
}

@keyframes fadeInAnimation {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.notifications-container-txt {
	color: white;
	font-size: x-large;
	font-family: "Mollen";
}
