.modal-container-wrapper {
	display: flex;
	width: 100%;
	height: 100%;
	justify-content: center;
	align-items: center;
	z-index: 10;
	background-color: rgba(0, 0, 0, 0.5);
	position: absolute;
}

.modal-container-form-wrapper {
	display: flex;
	flex-direction: column;
}

.modal-container-header {
	display: flex;
	position: relative;
	justify-content: center;
	align-items: center;
	background-color: #213a44;
	padding: 20px;
	border-radius: 12px 12px 0px 0px;
}

.modal-container-title-txt {
	font-size: xx-large;
	font-family: "MollenBold";
	color: white;
}

.modal-container-exit-txt {
	font-size: x-large;
	font-family: "MollenBold";
	color: white;
	position: absolute;
	top: 10px;
	right: 10px;
	cursor: pointer;
}

.modal-container-body {
	padding: 10px;
	background-color: white;
}
