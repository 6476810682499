.history-page-container {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	padding-top: 20px;
	padding-bottom: 20px;
	padding-left: 40px;
	padding-right: 40px;
}

.history-page-table-container {
	display: flex;
	height: 100%;
	flex-direction: column;
	width: 100%;
	background-color: white;
	border-radius: 12px;
}

.history-page-table-body-container {
	display: flex;
	flex-direction: column;
	padding: 10px;
	height: 100%;
}

.history-page-table-body-wrapper {
	display: flex;
	width: 100%;
	flex-direction: row;
	height: 100%;
}

.history-page-table-holder {
	display: flex;
	flex: 2;
	position: relative;
}

.history-page-table-rows-container {
	display: flex;
	flex-direction: column;
	flex: 2;
	height: 100%;
	max-height: 100%;
	min-height: 410px;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	overflow: auto;
}

.history-page-table-header-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #213a44;
	border-radius: 12px;
	padding-top: 10px;
	padding-bottom: 10px;
}

.history-page-table-header-title-txt {
	font-family: "Mollen";
	font-size: xx-large;
	color: white;
}

.history-page-table-header-container {
	display: flex;
	width: 100%;
	padding-top: 10px;
	padding-bottom: 10px;
	flex-direction: row;
	background-color: #23c8aa;
}

.history-page-table-header-txt {
	font-family: "MollenBold";
	font-size: x-large;
	color: white;
}

.history-page-table-header-info-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-around;
	flex: 2;
}

.history-page-table-header-trigger-image-container {
	display: flex;
	flex: 1;
	justify-content: center;
	align-items: center;
}

.history-page-table-row-txt {
	font-family: "Mollen";
	font-size: large;
	color: #213a44;
}

.history-page-table-row-txt-status {
	font-family: "Mollen";
	font-size: large;
	color: #23c8aa;
}

.history-page-table-row-txt-status-declined {
	font-family: "Mollen";
	font-size: large;
	color: red;
}

.history-page-table-row-txt-status-pending {
	font-family: "Mollen";
	font-size: large;
	color: orange;
}

.history-page-table-row-txt-status-approved {
	font-family: "Mollen";
	font-size: large;
	color: green;
}

.history-page-table-rows-image-container {
	display: flex;
	flex: 1;
	flex-direction: column;
}

.history-page-trigger-image-container {
	display: flex;
	width: 100%;
	flex-direction: column;
}

.history-page-trigger-image-header-container {
	display: flex;
	width: 100%;
	height: 56.5px;
	justify-content: center;
	align-items: center;
	background-color: #23c8aa;
}

.history-page-trigger-image-wrapper {
	border: 1px solid;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 20px;
	gap: 10px;
	cursor: pointer;
}

.history-page-trigger-image-txt {
	font-family: "Mollen";
	font-size: large;
	color: #213a44;
}

.action-icon {
	height: fit-content;
	width: fit-content;
	border-radius: 100px;
	padding-top: 5px;
	padding-left: 5px;
	padding-right: 5px;
}

.action-icon:hover {
	background-color: rgb(214, 211, 211);
	cursor: pointer;
}

.action-icons-container {
	display: flex;
	flex-direction: row;
}

.history-page-stats-container {
	width: 100%;
	height: 100%;
}

.history-page-graph-container {
	height: 100%;
	max-height: 100%;
	width: 50%;
}
