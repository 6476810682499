.camera-preview-image {
	max-width: 100%;
	height: auto;
	object-fit: contain;
	overflow: hidden;
}

.loading-wrapper {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 175px;
	min-width: 305px;
}

.normal-txt-prog-img {
	font-family: "Mollen";
	font-size: x-large;
	color: #213a44;
}
