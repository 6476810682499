* {
  box-sizing: border-box;
}

.camera-stream-modal-wrapper {
  display: flex;
  z-index: 10;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  height: 100vh;
  width: 100%;
  flex-direction: column;
  align-content: center;
  align-items: center;
}

.camera-stream-modal-form-wrapper {
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 12px;
  width: "fit-content";
  width: 70%;
}

.aa {
  height: 10%;
  width: 100%;
}

.camera-stream-modal-header {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  background-color: #213a44;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 12px 12px 0px 0px;
}

.camera-stream-modal-body {
  display: flex;
  flex-direction: row;
  padding: 10px;
}

.camera-stream-frame {
  width: 100%;
  height: 65vh;
  min-height: 400px;
}

.camera-stream-modal-title-txt {
  font-size: xx-large;
  font-family: "MollenBold";
  color: white;
}

.camera-stream-modal-normal-txt {
  font-family: "Mollen";
  font-size: large;
  color: #213a44;
}

.camera-stream-modal-exit-txt {
  font-size: x-large;
  font-family: "MollenBold";
  color: white;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
