.profile-page-body {
	display: flex;
	flex-direction: row;
	height: 100%;
	padding: 20px;
	box-sizing: border-box;
	overflow: hidden;
}

.personal-info-wrapper {
	display: flex;
	flex: 1;
	margin-right: 20px;
}

.activity-info-wrapper {
	display: flex;
	flex: 2;
	margin-right: 20px;
	box-sizing: border-box;
}

.companies-wrapper {
	display: flex;
	flex: 1;
}
