.history-page-filters-container {
	display: flex;
	flex-direction: row;
	width: 100%;
	align-items: center;
	margin-top: 10px;
	padding-left: 10px;
	padding-right: 10px;
	gap: 20px;
}

.history-page-filters-select-container {
	min-width: fit-content;
	width: 15%;
}

.history-page-filters-button-txt {
	font-family: "Mollen";
	font-size: large;
	color: white;
}
