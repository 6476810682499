.loading-modal-component-wrapper {
  z-index: 15;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.loading-modal-txt {
  font-size: xx-large;
  font-family: "Nunito", sans-serif;
  color: white;
}
