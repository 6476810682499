.personal-info-component-wrapper {
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	height: 100%;
	background-color: white;
	padding: 20px;
	border-radius: 12px;
}

.personal-info-photo-section-wrapper {
	display: flex;
	flex-direction: column;
	height: 40%;
	max-height: 40%;
	margin-bottom: 10px;
}

.photo-wrapper {
	display: flex;
	height: 100%;
	width: 100%;
	max-height: 100%;
	background-color: #213a44;
	border-radius: 12px;
	border: 0px none;
}

.profile-image {
	width: 100%;
	height: 100%;
	object-fit: contain;
	overflow: hidden;
}

.personal-info-wrapper {
	display: flex;
	flex-direction: column;
	/* justify-content: space-between; */
	width: 100%;
	flex: 1;
	gap: 10%;
}

.name-wrapper {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	margin-top: 10px;
	margin-bottom: 10px;
}

.first-name-wrapper {
	flex: 1;
	margin-right: 10px;
}

.last-name-wrapper {
	flex: 1;
}

.contact-info-wrapper {
	display: flex;
	flex-direction: column;
	margin-top: 10px;
	margin-bottom: 10px;
}

.phone-number-wrapper {
	flex: 1;
}

.button {
	width: 100%;
	height: 75px;
	font-size: x-large;
	padding-top: 10px;
	padding-bottom: 10px;
	background-color: #213a44;
	border-radius: 10px;
	color: white;
	font-family: "MollenBold";
	border-width: 0px;
	cursor: pointer;
}

.subtitle-txt {
	font-family: "MollenBold";
	font-size: x-large;
	color: #213a44;
}

.normal-txt {
	font-family: "Mollen";
	font-size: large;
	color: #213a44;
}

.field-txt {
	font-family: "Mollen";
	font-size: x-large;
	color: #23c8aa;
	border: 1px solid black;
	border-radius: 5px;
	padding: 5px;
}
