* {
  box-sizing: border-box;
}

.edit-info-modal-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 100%;
}

.edit-info-modal-form-wrapper {
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 12px;
  width: 40%;
}

.edit-info-modal-header {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  background-color: #213a44;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 12px 12px 0px 0px;
}

.edit-info-modal-body {
  display: flex;
  flex-direction: row;
}

.edit-info-modal-inputs-wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: 20px;
  margin-right: 20px;
}

.edit-info-modal-input-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: 20px;
}

.edit-info-modal-pic-wrapper {
  display: flex;
  flex: 1;
}

.edit-info-modal-button {
  width: 50%;
  height: 15%;
  font-size: x-large;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #213a44;
  border-radius: 10px;
  color: white;
  font-family: "MollenBold";
  border-width: 0px;
  cursor: pointer;
  align-self: center;
  margin-bottom: 20px;
  margin-top: 20px;
}

.edit-info-modal-title-txt {
  font-size: xx-large;
  font-family: "MollenBold";
  color: white;
}

.edit-info-modal-normal-txt {
  font-family: "Mollen";
  font-size: large;
  color: #213a44;
}

.edit-info-modal-exit-txt {
  font-size: x-large;
  font-family: "MollenBold";
  color: white;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
