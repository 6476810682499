.weather-modal-icon {
	border-radius: 36px;
	background-color: #23c8aa;
	padding: 30px;
	border-color: #213a44;
	border: 1px solid;
	position: relative;
}

.weather-modal-icon-img {
	width: 110px;
	height: 110px;
	object-fit: contain;
}

.weather-modal-inactive-icon-overlay {
	width: 100%;
	height: 100%;
	top: 0px;
	bottom: 0px;
	left: 0px;
	right: 0px;
	z-index: 10;
	position: absolute;
	background-color: rgba(0, 0, 0, 0.7);
	border-radius: 36px;
}
