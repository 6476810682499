.suboption-container {
	display: flex;
	justify-content: space-between;
	padding-left: 20px;
	padding-right: 20px;
	align-items: center;
}

.suboption-title-txt {
	font-family: "Mollen";
	font-size: x-large;
	color: white;
}

.suboption-container-second {
	display: flex;
	padding-left: 30px;
	padding-right: 20px;
	align-items: center;
	gap: 10%;
	cursor: pointer;
}

.suboption-wrapper {
	display: flex;
	flex-direction: column;
	gap: 20px;
}
