.edit-camera-modal-container {
	display: flex;
	flex-direction: row;
	gap: 10px;
	min-width: 600px;
}

.edit-camera-modal-form-container {
	display: flex;
	flex-direction: column;
	flex: 1;
	min-width: 250px;
	margin: 10px;
}

.edit-camera-modal-map-container {
	display: flex;
	flex-direction: column;
	flex: 1;
}

.edit-camera-modal-footer {
	display: flex;
	width: 100%;
	margin-top: 20px;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	gap: 20px;
}

.edit-camera-delete-btn {
	font-family: "Mollen";
	font-size: large;
	color: red;
}
