.input-field {
	height: 50px;
	width: 100%;
	font-size: x-large;
	border: 1px solid;
	padding-left: 10px;
	border-color: #1f3943;
	font-family: "Mollen";
}

.input-field:focus {
	outline: 1.5px solid;
	outline-color: #1f3943;
	border-left: 5px solid;
	border-color: #1f3943;
}
