.login-page-container {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: row;
}

.login-form-container {
	display: flex;
	flex: 1;
	flex-direction: column;
	background-color: white;
}

/* --- LOGO --- */

.login-form-logo-container {
	display: flex;
	height: 10%;
	width: 100%;
	margin-left: 10%;
}

.login-form-logo {
	object-fit: contain;
}

/* --- WELCOME --- */

.login-form-welcome-container {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	height: 30%;
	width: 100%;
	padding-left: 20%;
}

.login-form-welcome-title-txt {
	font-size: 75px;
	color: #37c8ab;
	font-family: "Mollen";
}

.login-form-welcome-subtitle-txt {
	font-size: 75px;
	font-weight: bold;
	color: #1f3943;
	font-family: "MollenBold";
}

/* --- INPUT --- */

.login-form-input-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 30%;
	width: 100%;
}

.login-form-input {
	height: 50px;
	width: 60%;
	font-size: x-large;
	border: 1px solid;
	border-color: #1f3943;
	margin-top: 5px;
	padding-left: 10px;
}

.login-form-input:focus {
	outline: 1.5px solid;
	outline-color: #1f3943;
	border-left: 5px solid;
	border-color: #1f3943;
}

.login-form-input-buttons-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	margin-top: 10px;
	margin-bottom: 10px;
	width: 60%;
}

.login-form-input-buttons-remmember-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.login-form-input-login-button-container {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	padding-top: 10px;
	width: 60%;
}

.login-form-input-login-button {
	font-size: x-large;
	padding-top: 10px;
	padding-bottom: 10px;
	padding-left: 20px;
	padding-right: 20px;
	background-color: transparent;
	border-radius: 12px;
	align-self: flex-end;
	color: #37c8ab;
	border-color: #37c8ab;
	font-family: "MollenBold";
	cursor: pointer;
}

.login-form-input-login-button:hover {
	background-color: #fa6e05;
	color: #36474f;
	border-color: #fa6e05;
}

.login-form-input-buttons-txt {
	font-family: "Mollen";
	color: #37c8ab;
	cursor: pointer;
}

.login-form-input-buttons-remmember-checkbox-container {
	margin-right: 10px;
	padding-bottom: 2px;
}

/* --- BOTTOM OPTIONS --- */

.login-form-options-container {
	display: flex;
	flex-direction: column;
	height: 30%;
	width: 100%;
	padding-left: 20%;
}

.login-form-options-txt {
	font-family: "Mollen";
	font-size: large;
	margin-top: 5%;
}

/* --- QUOTES --- */

.login-quotes-container {
	display: flex;
	flex: 1;
	flex-direction: column;
	background-color: #36474f;
	background-image: url("../../assets/illustration.svg");
}
