.activity-component-wrapper {
	display: flex;
	box-sizing: border-box;
	flex-direction: column;
	align-items: center;
	padding: 20px;
	flex: 1;
	height: 100%;
	border-radius: 12px;
	background-color: white;
}

.activity-title-txt {
	font-family: "MollenBold";
	font-size: xx-large;
	color: #213a44;
	margin-bottom: 10px;
}

.activity-diagram-title-txt {
	font-family: "MollenBold";
	font-size: x-large;
	color: #23c8aa;
	margin-bottom: 10px;
}

.phone-box {
	width: 100%;
	height: 100%;
	margin-bottom: 10px;
	overflow-y: auto;
}

.phone-wrapper {
	width: 100%;
	font-family: "Mollen";
	font-size: x-large;
	color: #213a44;
	border: 1px solid black;
	border-radius: 5px;
	padding: 5px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 20px;
}

.phone-delete {
	cursor: pointer;
	color: red;
	display: flex;
	align-items: center;
	margin: 0px;
	padding: 0px;
}

.actions-wrapper {
	display: flex;
	flex-direction: row;
	width: 8%;
	justify-content: space-between;
	align-items: center;
}

.phone-test-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}

.button-phone {
	width: 60%;
	height: 75px;
	font-size: x-large;
	padding-top: 10px;
	padding-bottom: 10px;
	background-color: #213a44;
	border-radius: 10px;
	color: white;
	font-family: "MollenBold";
	border-width: 0px;
	cursor: pointer;
}

.phone-number {
	font-size: 20px;
}
