.login-page-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
}

.login-form-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: white;
}

/* --- LOGO --- */

.login-form-logo-container {
  display: flex;
  height: 10%;
  width: 100%;
  margin-left: 10%;
}

.login-form-logo {
  object-fit: contain;
}

/* --- WELCOME --- */

.login-form-welcome-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 30%;
  width: 100%;
  padding-left: 20%;
}

.login-form-welcome-title-txt {
  font-size: 75px;
  color: #37c8ab;
  font-family: "Mollen";
}

.login-form-welcome-subtitle-txt {
  font-size: 75px;
  font-weight: bold;
  color: #1f3943;
  font-family: "MollenBold";
}

/* --- INPUT --- */

.login-form-input-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: fit-content;
  width: 100%;
}

.login-form-line-double-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 60%;
  margin-bottom: 10px;
}

.login-form-line-double-input-wrapper {
  width: 45%;
}

.login-form-line-container {
  width: 60%;
  margin-bottom: 10px;
}

.login-form-input-login-button {
  font-size: x-large;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: transparent;
  border-radius: 12px;
  align-self: flex-end;
  color: #37c8ab;
  border-color: #37c8ab;
  font-family: "MollenBold";
  align-self: flex-end;
}

.login-form-input-buttons-txt {
  font-family: "Mollen";
  color: #37c8ab;
}

/* --- BOTTOM OPTIONS --- */

.login-form-options-container {
  display: flex;
  flex-direction: column;
  width: 80%;
  padding-left: 20%;
  padding-right: 20%;
}

.login-form-options-txt {
  font-family: "Mollen";
  font-size: large;
  margin-top: 5%;
}

.login-form-options-actions-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.login-form-options-button-container {
  display: flex;
  margin-right: 20%;
  justify-content: flex-end;
}

/* --- QUOTES --- */

.login-quotes-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: #36474f;
}
