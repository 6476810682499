.cameras-page-body {
	display: flex;
	height: 100%;
	width: 100%;
}

/* CAMERAS CAPACITY AND LIST */

.cameras-page-left-side-container {
	display: flex;
	flex-direction: column;
	flex: 3;
	margin-right: 20px;
	margin-top: 20px;
	margin-left: 20px;
	margin-bottom: 20px;
}

/* CAMERAS CAPACITY CONTAINER AND CONTENT */

.cameras-page-capacity-container {
	display: flex;
	box-sizing: border-box;
	flex-direction: row;
	flex: 1;
	margin-bottom: 20px;
	max-height: 35%;
}

.cameras-page-capacity-diagram-container {
	display: flex;
	box-sizing: border-box;
	padding: 10px;
	flex: 1;
	background-color: white;
}

.cameras-page-capacity-utilization-container {
	display: flex;
	flex-direction: column;
	flex: 2;
	background-color: white;
}

.cameras-page-capacity-utilization-content {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	flex: 1;
	margin: 10px;
}

.cameras-page-capacity-utilization-defs-stats-container {
	display: flex;
	flex-direction: row;
	height: 40%;
}

.cameras-page-capacity-utilization-defs {
	display: flex;
	flex: 1;
	flex-direction: column;
	justify-content: space-between;
	padding-right: 20px;
}

.cameras-page-capacity-utilization-stats {
	display: flex;
	flex: 1;
	flex-direction: column;
	justify-content: space-between;
	padding-left: 20px;
}

.stats-title-txt {
	font-family: "MollenBold";
	font-size: x-large;
	text-overflow: ellipsis;
	color: #23c8aa;
	white-space: nowrap;
}

.stats-subtitle-txt {
	font-family: "Mollen";
	font-size: large;
	text-overflow: ellipsis;
	color: #213a44;
	white-space: nowrap;
}

.title-txt {
	font-family: "MollenBold";
	font-size: x-large;
	color: #213a44;
}

.button-body {
	width: 80%;
	font-size: x-large;
	padding-top: 10px;
	padding-bottom: 10px;
	background-color: #213a44;
	border-radius: 10px;
	color: white;
	font-family: "MollenBold";
	border-width: 0px;
	cursor: pointer;
}

/* CAMERAS LIST */

.cameras-page-cameras-container {
	display: flex;
	flex: 2;
	overflow: auto;
	background-color: white;
	box-sizing: border-box;
}

/* CAMERAS HISTORY LIST */

.cameras-page-right-side-container {
	display: flex;
	flex-direction: column;
	flex: 1;
	margin-top: 20px;
	margin-right: 20px;
	margin-bottom: 20px;
}

.cameras-page-cameras-history-list-container {
	display: flex;
	width: 100%;
	max-height: 100%;
	height: 85%;
}

.cameras-page-new-camera-container {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 15%;
	background-color: white;
}

.button-body-add-new-camera {
	width: 80%;
	height: 70%;
	font-size: x-large;
	padding-top: 10px;
	padding-bottom: 10px;
	background-color: #213a44;
	border-radius: 10px;
	color: white;
	font-family: "MollenBold";
	border-width: 0px;
	cursor: pointer;
}
