.header-component-body {
	display: flex;
	flex-direction: row;
	height: 10%;
	width: 100%;
}

.logo-box {
	display: flex;
	width: 15%;
	justify-content: center;
	align-items: center;
	background-color: #23c8aa;
}

.header-body-box {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex: 1;
	background-color: #213a44;
	align-content: space-between;
}

.user-company-selectors-box {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-self: flex-end;
	margin-right: 20px;
}

.user-icon-box {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	width: 50px;
	height: 50px;
	margin-left: 20px;
	border-radius: 100px;
	background-color: white;
	cursor: pointer;
}

.username-box {
	display: flex;
	justify-content: center;
	align-items: center;
}

.logo-txt {
	color: white;
	font-size: 20px;
	font-size: xx-large;
	font-family: "Nunito", sans-serif;
}

.username-txt {
	color: white;
	font-size: 20px;
	font-size: x-large;
	font-family: "Nunito", sans-serif;
}

.company-selector-box {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 250px;
	margin-right: 40px;
}

.selector {
	background-color: white;
}
