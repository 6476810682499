body {
	margin: 0px;
	padding: 0px;
}

p {
	margin: 0px;
	padding: 0px;
}

@font-face {
	font-family: "Mollen";
	src: local("Mollen"),
		url("../../assets/fonts/Mollen\ Personal\ Use-Regular.otf")
			format("opentype");
}

@font-face {
	font-family: "MollenBold";
	src: local("MollenBold"),
		url("../../assets/fonts/Mollen\ Personal\ Use-Bold.otf") format("opentype");
}

@font-face {
	font-family: "MollenBoldNarrow";
	src: local("MollenBoldNarrow"),
		url("../../assets/fonts/Mollen\ Personal\ Use-BoldNarrow.otf")
			format("opentype");
}

:root {
	--localGrey: #213a44;
	--localGreen: #23c8aa;
	--localOrange: #fa6e05;
	--localLightBlueBg: #e7ebf0;
}

.app-body {
	width: 100vw;
	height: 100vh;
	box-sizing: border-box;
	background-color: #f9fbfc;
}
