.weather-modal-body-container {
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.weather-modal-title-container {
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;
	margin-top: 10px;
}

.weather-modal-title-txt {
	font-family: "MollenBold";
	font-size: xx-large;
	color: #213a44;
}

.weather-modal-body-wrapper {
	display: flex;
	flex-direction: row;
	position: relative;
	gap: 50px;
}

.weather-modal-icons-container {
	display: flex;
	flex: 3;
	flex-direction: column;
	gap: 10px;
}

.weather-modal-info-container {
	display: flex;
	flex-direction: column;
	flex: 2;
	top: 0;
	bottom: 0;
	justify-content: space-around;
	align-items: center;
}

.weather-modal-icons-row-container {
	display: flex;
	flex-direction: row;
	gap: 10px;
}

.weather-modal-info-row {
	display: flex;
	flex-direction: row;
	gap: 20px;
}

.weather-modal-info-def {
	display: flex;
	background-color: #213a44;
	padding-top: 5px;
	padding-bottom: 5px;
	padding-left: 10px;
	padding-right: 10px;
	width: 150px;
	justify-content: center;
	align-items: center;
	border-radius: 10px;
}

.weather-modal-info-value {
	display: flex;
	background-color: #23c8aa;
	width: 75px;
	padding-top: 5px;
	padding-bottom: 5px;
	padding-left: 10px;
	padding-right: 10px;
	justify-content: center;
	align-items: center;
	border-radius: 10px;
}

.weather-modal-info-def-txt {
	font-family: "MollenBold";
	font-size: x-large;
	color: white;
}

.weather-modal-loading-container {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 250px;
	width: 100%;
}
