.camera-history-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 200px;
  margin-bottom: 10px;
  background-color: white;
  position: relative;
  border-color: #213a44;
  border: 1px solid;
  box-sizing: border-box;
  padding: 5px;
  overflow: hidden;
}

.camera-history-edit-button {
  width: 45px;
  height: 45px;
  position: absolute;
  right: 5px;
  top: 5px;
}

.camera-history-text-title {
  font-family: "MollenBold";
  font-size: x-large;
  color: #213a44;
}

.camera-history-text-normal {
  font-family: "Mollen";
  font-size: large;
  text-overflow: ellipsis;
  color: #213a44;
}
