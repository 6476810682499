.company-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: fit-content;
  margin-bottom: 10px;
  padding: 20px;
}

.icon-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 2;
}

.info-wrapper {
  display: flex;
  flex-direction: column;
  flex: 3;
}

.normal-txt {
  font-family: "Mollen";
  font-size: large;
  color: #213a44;
}

.normal-txt-bold {
  font-family: "MollenBold";
  font-size: large;
  color: #213a44;
}
