.side-menu-options-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  padding-top: 25px;
  padding-bottom: 25px;
  margin-bottom: 10px;
  cursor: pointer;
}

.side-menu-options-icon {
  width: 30%;
  display: flex;
  justify-content: center;
}
