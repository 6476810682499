.new-company-modal-body {
	display: flex;
	flex-direction: column;
	width: 100%;
	align-self: center;
}

.new-company-modal-normal-txt {
	font-family: "Mollen";
	font-size: large;
	color: #213a44;
	margin-bottom: 20px;
}
