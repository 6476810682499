.companies-component-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: white;
  padding: 20px;
  border-radius: 12px;
}

.companies-title-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.companies-list-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  margin-top: 20px;
  box-sizing: border-box;
}

.button_comp {
  width: 100%;
  height: 75px;
  font-size: x-large;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #213a44;
  border-radius: 10px;
  color: white;
  font-family: "MollenBold";
  border-width: 0px;
  margin-top: 20px;
  cursor: pointer;
}

.subtitle-txt {
  font-family: "MollenBold";
  font-size: x-large;
  color: #213a44;
}
